import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import data from "../../content/pages/partners-network.yaml"
import "./partners-network.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Telephone, GeoAlt, Globe, BoxArrowUpRight } from "react-bootstrap-icons"
import ReCAPTCHA from "react-google-recaptcha";
import heroBg from "../images/limpar-section-background-3-q80.webp"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios";

const PartnersNetworkPage = () => {
  const partnerData = useStaticQuery(graphql`
    query {
      allStrapiPartnerCity(sort: {order: ASC, fields: order}) {
        nodes {
          cityName
          numberOfPartners
          phone
        }
      }
    }
  `)
    const [borderAnimation, setBorderAnimation] = React.useState(false)

  const recaptchaRef = React.createRef();
  const [response, setResponse] = React.useState({ message: null, status: null});

  const handleSubmit = e => {
    e.preventDefault();
    console.log(e.target.formName.value);
    if (
      e.target.formName.value
      && e.target.formCompany.value
      && e.target.formEmail.value
      && e.target.formPhone.value
      && e.target.formMessage.value
      && e.target.formBasicCheckbox.value) {
      axios.post("https://limpar.gr/form/partnership/", {
        name: e.target.formName.value,
        from: e.target.formEmail.value,
        phone: e.target.formPhone.value,
        company: e.target.formCompany.value,
        message: e.target.formMessage.value,
        recaptcha: recaptchaRef?.current?.getValue()
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        console.log(response);
        setResponse({
          message: "Ευχαριστούμε για το μήνυμα, θα επικοινωνήσουμε μαζί σας το συντομότερο.",
          status: "success"
        })
      })
      .catch(function (error) {
        console.log(error);
        setResponse({
          message: "Δυστυχώς υπήρξε ένα πρόβλημα με την φόρμα επικοινωνίας, προσπαθήστε ξανά αργότερα ή επικοινωνήστε μαζί μας τηλεφωνικά ή μέσω email",
          status: "danger"
        })
      });
    } else {
      setResponse({
        message: "Παρακαλώ συμπληρώστε όλα τα πεδία και συμφωνήστε με τους όρους χρήσης",
        status: "warning"
      })
    }
  }

    React.useEffect(
        () => {
          const checkItemPosition = () => {
            const element = document.querySelector('#lp-parnters-hero')

            setBorderAnimation(Math.abs(element.getBoundingClientRect().top) > element.getBoundingClientRect().height - window.innerHeight / 2)
          }

          window.addEventListener('scroll', checkItemPosition )
          return () => window.removeEventListener('scroll', checkItemPosition)
        }, []
    )

    return (
      <Layout headerColorMode="dark" pageTitle={ data.meta.title } enableBorderAnim>
        <section id="lp-parnters-hero"
          style={{
            backgroundImage: `url("${heroBg}")`
          }}
          className={`lp-partners-hero container-fluid lp-dark-hero lp-border-transition lp-btm-right-radius-transition ${ borderAnimation ? 'active' : '' }`}
        >
          <div className="container">
            <div className="row mb-5">
              <div className="col-12">
                <h2 className="lp-dotted-title">{data.content.hero.title}</h2>
                {data.content.hero.text.map((paragraph, index) => {
                  ;<p key={index}>{paragraph}</p>
                })}
              </div>
            </div>
            <div
              id="lp-partners-list"
              className="row lp-partners-list"
            >
              {partnerData.allStrapiPartnerCity.nodes.map((cityData, i) => {
                return (
                  <div
                    className="col-12 col-sm-6 col-lg-4 col-xxl-6 mt-5 lp-city-partner-details"
                    key={i}
                  >
                    <div className="row">
                      <div className="col-12 col-xxl-6 lp-partner-city text-end">
                        <h3>{cityData.cityName}</h3>
                      </div>
                      <div className="col lp-partner-city-list">
                        <ul>
                          <li className="lp-partner-city-details pt-2">
                            <h4>{cityData.numberOfPartners} {cityData.numberOfPartners > 1 && "Σημεία" || "Σημείο"}</h4>
                            <div>
                              <Telephone className={`me-2`}/>
                              <a style={{ color: `#fff`}} href={`tel:${cityData.phone}`}>
                                {
                                  cityData.phone == 2241056554
                                  ?  cityData.phone
                                  : "Καλέστε μας"
                                }
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="lp-partners-become container">
          <div className="row">
            <div className="col-10">
              <div className="row gx-5">
                <div className="col-12 col-xl-4 text-center text-xl-end lp-parterns-form-text">
                  <h2 className="mb-3">{data.content.contact.title}</h2>
                  <p>{data.content.contact.text}</p>
                </div>
                <div className="col-12 col-xl-8">
                  <Form className="lp-partners-form" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Control
                        type="text"
                        placeholder={data.content.contact.form.namePlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCompany">
                      <Form.Control
                        type="text"
                        placeholder={data.content.contact.form.companyPlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Control
                        type="text"
                        placeholder={data.content.contact.form.phonePlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Control
                        type="text"
                        placeholder={data.content.contact.form.emailPlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formMessage">
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder={data.content.contact.form.messagePlaceholder}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label={ data.content.contact.form.consentText} />
                    </Form.Group>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Le5B-AiAAAAAHVXK6sMUUT-Xz0xdzLDoyBqsbuS"
                    />

                  { response?.message &&
                    <div className={`alert alert-${response.status}`} role="alert">
                      {response.message}
                    </div>
                  }

                    <Button variant="primary" type="submit" className="mt-2 px-4 lp-styled-btn">
                      {data.content.contact.form.submitText}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
}

export const Head = () => <Seo title={ data.meta.title } />

export default PartnersNetworkPage


